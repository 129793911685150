import JWTDecode from 'jwt-decode'
import * as GraphqlTypes from '~/graphql/types'

export default function ({ app, redirect }) {
  // If the user is not authenticated
  const token: string = app.$apolloHelpers.getToken()
  if (!token) {
    return redirect('/login')
  }

  const tokenDecoded: any = JWTDecode(token)
  const rolesCurrentUser: string[] =
    tokenDecoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
  if (!rolesCurrentUser.includes(GraphqlTypes.Role_Enum.Admin)) {
    return redirect('/')
  }
}
