import { InMemoryCache } from 'apollo-cache-inmemory'

import { onError } from 'apollo-link-error'

export default (context) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(async ({ message }) => {
        // @ts-ignore
        await window.$nuxt.$store.commit('setLastErrorMessage', '')
        await window.$nuxt.$store.commit('setLastErrorMessage', message)
      })
    }

    if (networkError && (<any>networkError).statusCode === 401) {
      window.location.href = '/logout'
    }
  })

  return {
    link: errorLink,
    httpEndpoint: `https://${context.$config.hasuraDomain}/v1/graphql`,
    wsEndpoint: `wss://${context.$config.hasuraDomain}/v1/graphql`,
    apollo: {
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        subscription: {
          fetchPolicy: 'no-cache',
          // fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    },
  }
}
