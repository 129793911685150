import JWTDecode from 'jwt-decode'
import moment from 'moment'

export default function ({ app, store, redirect }) {
  // If the user is not authenticated
  const jwtToken = app.$apolloHelpers.getToken()
  if (!jwtToken) {
    return redirect('/login')
  }
  const tokenDecoded: any = JWTDecode(jwtToken)
  const logoutAfter = Math.max(
    0,
    moment.unix(tokenDecoded.exp).diff(moment(), 'seconds')
  )

  setTimeout(async () => {
    await window.$nuxt.$store.commit('setLastErrorMessage', '')
    await window.$nuxt.$store.commit(
      'setLastErrorMessage',
      'Authentication expired. New login is required'
    )
    setTimeout(async () => {
      await app.$apolloHelpers.onLogout()
      await store.dispatch('loginLogout')
      return redirect('/login')
    }, 2000)
  }, logoutAfter * 1000)

  return true
}
