


























import { Vue, Component, State, Watch } from 'nuxt-property-decorator'

@Component
export default class SnackBarMessagesError extends Vue {
  @State('lastErrorMessage') lastErrorMessage

  showSnackbarError = false

  mounted() {
    this.showSnackbarError = this.lastErrorMessage !== ''
  }

  @Watch('lastErrorMessage')
  onLastErrorMessageChanged(value: string) {
    this.showSnackbarError = value !== ''
  }

  @Watch('showSnackbar')
  onShowSnackbarChanged(value: boolean) {
    if (!value) {
      this.$store.commit('setLastErrorMessage', '')
    }
  }
}
