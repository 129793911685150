










import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import SnackBarMessagesError from '~/components/SnackBarMessagesError.vue'
import SnackBarMessagesSuccess from '~/components/SnackBarMessagesSuccess.vue'

@Component({
  middleware: 'notAuthenticated',
  components: {
    SnackBarMessagesSuccess,
    SnackBarMessagesError,
  },
})
export default class Menu extends Vue {}
