






































































































import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import Logo from '~/components/Logo.vue'
import MenuMyAccount from '~/components/MenuMyAccount.vue'
import SnackBarMessagesError from '~/components/SnackBarMessagesError.vue'
import SnackBarMessagesSuccess from '~/components/SnackBarMessagesSuccess.vue'

@Component({
  components: {
    Logo,
    MenuMyAccount,
    SnackBarMessagesSuccess,
    SnackBarMessagesError,
  },
  middleware: 'authenticated',
})
export default class Menu extends Vue {
  isShow: boolean = false
  hasAdminRole: any
  showMenuAccount: boolean = false

  closeMenuMyAccount() {
    this.showMenuAccount = false
  }

  itemListAdminGroup: {
    icon: string
    label: string
    link: string
    color: string
  }[] = [
    {
      icon: 'loyalty',
      label: 'Tags',
      link: '/tags',
      color: 'white',
    },
    {
      icon: 'account_tree',
      label: 'Tag families',
      link: '/tag-families',
      color: 'white',
    },
    {
      icon: 'people',
      label: 'Users',
      link: '/users',
      color: 'white',
    },
  ]

  itemListEditorGroup: {
    icon: string
    label: string
    link: string
    color: string
  }[] = [
    {
      icon: 'category',
      label: 'Props',
      link: '/props',
      color: 'white',
    },
  ]
}
