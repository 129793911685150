import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

export type FileOutput = {
  __typename?: 'FileOutput'
  filepath: Scalars['String']
}

export type InsertUserOutput = {
  __typename?: 'InsertUserOutput'
  insertUserOutputEmail: Scalars['String']
  insertUserOutputFirstname: Scalars['String']
  insertUserOutputId: Scalars['String']
  insertUserOutputIsActive: Scalars['Boolean']
  insertUserOutputLastname: Scalars['String']
  insertUserOutputRole: Scalars['String']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type LoginOutput = {
  __typename?: 'LoginOutput'
  loginOutputAccessToken: Scalars['String']
}

export type MeOutput = {
  __typename?: 'MeOutput'
  meOutputEmail: Scalars['String']
  meOutputFirstname: Scalars['String']
  meOutputId: Scalars['String']
  meOutputLastname: Scalars['String']
  meOutputRole: Scalars['String']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput'
  updateUserOutputEmail: Scalars['String']
  updateUserOutputFirstname: Scalars['String']
  updateUserOutputId: Scalars['String']
  updateUserOutputIsActive: Scalars['Boolean']
  updateUserOutputLastname: Scalars['String']
  updateUserOutputRole: Scalars['String']
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "list" */
export type List = {
  __typename?: 'list'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An array relationship */
  list_props: Array<List_Prop>
  /** An aggregate relationship */
  list_props_aggregate: List_Prop_Aggregate
  name: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']
}

/** columns and relationships of "list" */
export type ListList_PropsArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

/** columns and relationships of "list" */
export type ListList_Props_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

/** aggregated selection of "list" */
export type List_Aggregate = {
  __typename?: 'list_aggregate'
  aggregate?: Maybe<List_Aggregate_Fields>
  nodes: Array<List>
}

export type List_Aggregate_Bool_Exp = {
  count?: InputMaybe<List_Aggregate_Bool_Exp_Count>
}

export type List_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<List_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<List_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "list" */
export type List_Aggregate_Fields = {
  __typename?: 'list_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<List_Max_Fields>
  min?: Maybe<List_Min_Fields>
}

/** aggregate fields of "list" */
export type List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "list" */
export type List_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<List_Max_Order_By>
  min?: InputMaybe<List_Min_Order_By>
}

/** input type for inserting array relation for remote table "list" */
export type List_Arr_Rel_Insert_Input = {
  data: Array<List_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<List_On_Conflict>
}

/** Boolean expression to filter rows from the table "list". All fields are combined with a logical 'AND'. */
export type List_Bool_Exp = {
  _and?: InputMaybe<Array<List_Bool_Exp>>
  _not?: InputMaybe<List_Bool_Exp>
  _or?: InputMaybe<Array<List_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  list_props?: InputMaybe<List_Prop_Bool_Exp>
  list_props_aggregate?: InputMaybe<List_Prop_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "list" */
export enum List_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListPkey = 'list_pkey',
}

/** input type for inserting data into table "list" */
export type List_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  list_props?: InputMaybe<List_Prop_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type List_Max_Fields = {
  __typename?: 'list_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "list" */
export type List_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type List_Min_Fields = {
  __typename?: 'list_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "list" */
export type List_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "list" */
export type List_Mutation_Response = {
  __typename?: 'list_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<List>
}

/** input type for inserting object relation for remote table "list" */
export type List_Obj_Rel_Insert_Input = {
  data: List_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<List_On_Conflict>
}

/** on_conflict condition type for table "list" */
export type List_On_Conflict = {
  constraint: List_Constraint
  update_columns?: Array<List_Update_Column>
  where?: InputMaybe<List_Bool_Exp>
}

/** Ordering options when selecting data from "list". */
export type List_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  list_props_aggregate?: InputMaybe<List_Prop_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: list */
export type List_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** columns and relationships of "list_prop" */
export type List_Prop = {
  __typename?: 'list_prop'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  list: List
  list_id: Scalars['uuid']
  /** An object relationship */
  prop: Prop
  prop_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "list_prop" */
export type List_Prop_Aggregate = {
  __typename?: 'list_prop_aggregate'
  aggregate?: Maybe<List_Prop_Aggregate_Fields>
  nodes: Array<List_Prop>
}

export type List_Prop_Aggregate_Bool_Exp = {
  count?: InputMaybe<List_Prop_Aggregate_Bool_Exp_Count>
}

export type List_Prop_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<List_Prop_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<List_Prop_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "list_prop" */
export type List_Prop_Aggregate_Fields = {
  __typename?: 'list_prop_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<List_Prop_Max_Fields>
  min?: Maybe<List_Prop_Min_Fields>
}

/** aggregate fields of "list_prop" */
export type List_Prop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Prop_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "list_prop" */
export type List_Prop_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<List_Prop_Max_Order_By>
  min?: InputMaybe<List_Prop_Min_Order_By>
}

/** input type for inserting array relation for remote table "list_prop" */
export type List_Prop_Arr_Rel_Insert_Input = {
  data: Array<List_Prop_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<List_Prop_On_Conflict>
}

/** Boolean expression to filter rows from the table "list_prop". All fields are combined with a logical 'AND'. */
export type List_Prop_Bool_Exp = {
  _and?: InputMaybe<Array<List_Prop_Bool_Exp>>
  _not?: InputMaybe<List_Prop_Bool_Exp>
  _or?: InputMaybe<Array<List_Prop_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  list?: InputMaybe<List_Bool_Exp>
  list_id?: InputMaybe<Uuid_Comparison_Exp>
  prop?: InputMaybe<Prop_Bool_Exp>
  prop_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "list_prop" */
export enum List_Prop_Constraint {
  /** unique or primary key constraint on columns "prop_id", "list_id" */
  ListPropListIdPropIdKey = 'list_prop_list_id_prop_id_key',
  /** unique or primary key constraint on columns "id" */
  ListPropPkey = 'list_prop_pkey',
}

/** input type for inserting data into table "list_prop" */
export type List_Prop_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  list?: InputMaybe<List_Obj_Rel_Insert_Input>
  list_id?: InputMaybe<Scalars['uuid']>
  prop?: InputMaybe<Prop_Obj_Rel_Insert_Input>
  prop_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type List_Prop_Max_Fields = {
  __typename?: 'list_prop_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  list_id?: Maybe<Scalars['uuid']>
  prop_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "list_prop" */
export type List_Prop_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  list_id?: InputMaybe<Order_By>
  prop_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type List_Prop_Min_Fields = {
  __typename?: 'list_prop_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  list_id?: Maybe<Scalars['uuid']>
  prop_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "list_prop" */
export type List_Prop_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  list_id?: InputMaybe<Order_By>
  prop_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "list_prop" */
export type List_Prop_Mutation_Response = {
  __typename?: 'list_prop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<List_Prop>
}

/** on_conflict condition type for table "list_prop" */
export type List_Prop_On_Conflict = {
  constraint: List_Prop_Constraint
  update_columns?: Array<List_Prop_Update_Column>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

/** Ordering options when selecting data from "list_prop". */
export type List_Prop_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  list?: InputMaybe<List_Order_By>
  list_id?: InputMaybe<Order_By>
  prop?: InputMaybe<Prop_Order_By>
  prop_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: list_prop */
export type List_Prop_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "list_prop" */
export enum List_Prop_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  PropId = 'prop_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "list_prop" */
export type List_Prop_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  list_id?: InputMaybe<Scalars['uuid']>
  prop_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "list_prop" */
export type List_Prop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Prop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type List_Prop_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  list_id?: InputMaybe<Scalars['uuid']>
  prop_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "list_prop" */
export enum List_Prop_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  PropId = 'prop_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type List_Prop_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<List_Prop_Set_Input>
  /** filter the rows which have to be updated */
  where: List_Prop_Bool_Exp
}

/** select columns of table "list" */
export enum List_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "list" */
export type List_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "list" */
export type List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type List_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "list" */
export enum List_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type List_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<List_Set_Input>
  /** filter the rows which have to be updated */
  where: List_Bool_Exp
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  api_insert_user: InsertUserOutput
  api_login: LoginOutput
  api_update_me: MeOutput
  api_update_user: UpdateUserOutput
  api_upload_file: FileOutput
  /** delete data from the table: "list" */
  delete_list?: Maybe<List_Mutation_Response>
  /** delete single row from the table: "list" */
  delete_list_by_pk?: Maybe<List>
  /** delete data from the table: "list_prop" */
  delete_list_prop?: Maybe<List_Prop_Mutation_Response>
  /** delete single row from the table: "list_prop" */
  delete_list_prop_by_pk?: Maybe<List_Prop>
  /** delete data from the table: "prop" */
  delete_prop?: Maybe<Prop_Mutation_Response>
  /** delete single row from the table: "prop" */
  delete_prop_by_pk?: Maybe<Prop>
  /** delete data from the table: "prop_tag" */
  delete_prop_tag?: Maybe<Prop_Tag_Mutation_Response>
  /** delete single row from the table: "prop_tag" */
  delete_prop_tag_by_pk?: Maybe<Prop_Tag>
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<Tag_Mutation_Response>
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<Tag>
  /** delete data from the table: "tag_family" */
  delete_tag_family?: Maybe<Tag_Family_Mutation_Response>
  /** delete single row from the table: "tag_family" */
  delete_tag_family_by_pk?: Maybe<Tag_Family>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** insert data into the table: "list" */
  insert_list?: Maybe<List_Mutation_Response>
  /** insert a single row into the table: "list" */
  insert_list_one?: Maybe<List>
  /** insert data into the table: "list_prop" */
  insert_list_prop?: Maybe<List_Prop_Mutation_Response>
  /** insert a single row into the table: "list_prop" */
  insert_list_prop_one?: Maybe<List_Prop>
  /** insert data into the table: "prop" */
  insert_prop?: Maybe<Prop_Mutation_Response>
  /** insert a single row into the table: "prop" */
  insert_prop_one?: Maybe<Prop>
  /** insert data into the table: "prop_tag" */
  insert_prop_tag?: Maybe<Prop_Tag_Mutation_Response>
  /** insert a single row into the table: "prop_tag" */
  insert_prop_tag_one?: Maybe<Prop_Tag>
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<Tag_Mutation_Response>
  /** insert data into the table: "tag_family" */
  insert_tag_family?: Maybe<Tag_Family_Mutation_Response>
  /** insert a single row into the table: "tag_family" */
  insert_tag_family_one?: Maybe<Tag_Family>
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<Tag>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** update data of the table: "list" */
  update_list?: Maybe<List_Mutation_Response>
  /** update single row of the table: "list" */
  update_list_by_pk?: Maybe<List>
  /** update multiples rows of table: "list" */
  update_list_many?: Maybe<Array<Maybe<List_Mutation_Response>>>
  /** update data of the table: "list_prop" */
  update_list_prop?: Maybe<List_Prop_Mutation_Response>
  /** update single row of the table: "list_prop" */
  update_list_prop_by_pk?: Maybe<List_Prop>
  /** update multiples rows of table: "list_prop" */
  update_list_prop_many?: Maybe<Array<Maybe<List_Prop_Mutation_Response>>>
  /** update data of the table: "prop" */
  update_prop?: Maybe<Prop_Mutation_Response>
  /** update single row of the table: "prop" */
  update_prop_by_pk?: Maybe<Prop>
  /** update multiples rows of table: "prop" */
  update_prop_many?: Maybe<Array<Maybe<Prop_Mutation_Response>>>
  /** update data of the table: "prop_tag" */
  update_prop_tag?: Maybe<Prop_Tag_Mutation_Response>
  /** update single row of the table: "prop_tag" */
  update_prop_tag_by_pk?: Maybe<Prop_Tag>
  /** update multiples rows of table: "prop_tag" */
  update_prop_tag_many?: Maybe<Array<Maybe<Prop_Tag_Mutation_Response>>>
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>
  /** update data of the table: "tag" */
  update_tag?: Maybe<Tag_Mutation_Response>
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<Tag>
  /** update data of the table: "tag_family" */
  update_tag_family?: Maybe<Tag_Family_Mutation_Response>
  /** update single row of the table: "tag_family" */
  update_tag_family_by_pk?: Maybe<Tag_Family>
  /** update multiples rows of table: "tag_family" */
  update_tag_family_many?: Maybe<Array<Maybe<Tag_Family_Mutation_Response>>>
  /** update multiples rows of table: "tag" */
  update_tag_many?: Maybe<Array<Maybe<Tag_Mutation_Response>>>
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootApi_Insert_UserArgs = {
  insertUserInputEmail: Scalars['String']
  insertUserInputFirstname: Scalars['String']
  insertUserInputIsActive: Scalars['Boolean']
  insertUserInputLastname: Scalars['String']
  insertUserInputPassword: Scalars['String']
  insertUserInputRole: Scalars['String']
}

/** mutation root */
export type Mutation_RootApi_LoginArgs = {
  loginInputEmail: Scalars['String']
  loginInputPassword: Scalars['String']
}

/** mutation root */
export type Mutation_RootApi_Update_MeArgs = {
  updateMeInputEmail: Scalars['String']
  updateMeInputFirstname: Scalars['String']
  updateMeInputLastname: Scalars['String']
  updateMeInputPassword: Scalars['String']
}

/** mutation root */
export type Mutation_RootApi_Update_UserArgs = {
  updateUserInputEmail: Scalars['String']
  updateUserInputFirstname: Scalars['String']
  updateUserInputId: Scalars['String']
  updateUserInputIsActive: Scalars['Boolean']
  updateUserInputLastname: Scalars['String']
  updateUserInputPassword: Scalars['String']
  updateUserInputRole: Scalars['String']
}

/** mutation root */
export type Mutation_RootApi_Upload_FileArgs = {
  base64str: Scalars['String']
  name: Scalars['String']
  prop_id: Scalars['String']
  type: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_ListArgs = {
  where: List_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_List_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_List_PropArgs = {
  where: List_Prop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_List_Prop_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_PropArgs = {
  where: Prop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Prop_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Prop_TagArgs = {
  where: Prop_Tag_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Prop_Tag_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  name: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_TagArgs = {
  where: Tag_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Tag_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Tag_FamilyArgs = {
  where: Tag_Family_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Tag_Family_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootInsert_ListArgs = {
  objects: Array<List_Insert_Input>
  on_conflict?: InputMaybe<List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_List_OneArgs = {
  object: List_Insert_Input
  on_conflict?: InputMaybe<List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_List_PropArgs = {
  objects: Array<List_Prop_Insert_Input>
  on_conflict?: InputMaybe<List_Prop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_List_Prop_OneArgs = {
  object: List_Prop_Insert_Input
  on_conflict?: InputMaybe<List_Prop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PropArgs = {
  objects: Array<Prop_Insert_Input>
  on_conflict?: InputMaybe<Prop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Prop_OneArgs = {
  object: Prop_Insert_Input
  on_conflict?: InputMaybe<Prop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Prop_TagArgs = {
  objects: Array<Prop_Tag_Insert_Input>
  on_conflict?: InputMaybe<Prop_Tag_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Prop_Tag_OneArgs = {
  object: Prop_Tag_Insert_Input
  on_conflict?: InputMaybe<Prop_Tag_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TagArgs = {
  objects: Array<Tag_Insert_Input>
  on_conflict?: InputMaybe<Tag_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tag_FamilyArgs = {
  objects: Array<Tag_Family_Insert_Input>
  on_conflict?: InputMaybe<Tag_Family_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tag_Family_OneArgs = {
  object: Tag_Family_Insert_Input
  on_conflict?: InputMaybe<Tag_Family_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tag_OneArgs = {
  object: Tag_Insert_Input
  on_conflict?: InputMaybe<Tag_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_ListArgs = {
  _set?: InputMaybe<List_Set_Input>
  where: List_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_List_By_PkArgs = {
  _set?: InputMaybe<List_Set_Input>
  pk_columns: List_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_List_ManyArgs = {
  updates: Array<List_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_List_PropArgs = {
  _set?: InputMaybe<List_Prop_Set_Input>
  where: List_Prop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_List_Prop_By_PkArgs = {
  _set?: InputMaybe<List_Prop_Set_Input>
  pk_columns: List_Prop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_List_Prop_ManyArgs = {
  updates: Array<List_Prop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PropArgs = {
  _inc?: InputMaybe<Prop_Inc_Input>
  _set?: InputMaybe<Prop_Set_Input>
  where: Prop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Prop_By_PkArgs = {
  _inc?: InputMaybe<Prop_Inc_Input>
  _set?: InputMaybe<Prop_Set_Input>
  pk_columns: Prop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Prop_ManyArgs = {
  updates: Array<Prop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Prop_TagArgs = {
  _set?: InputMaybe<Prop_Tag_Set_Input>
  where: Prop_Tag_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Prop_Tag_By_PkArgs = {
  _set?: InputMaybe<Prop_Tag_Set_Input>
  pk_columns: Prop_Tag_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Prop_Tag_ManyArgs = {
  updates: Array<Prop_Tag_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: InputMaybe<Role_Set_Input>
  where: Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: InputMaybe<Role_Set_Input>
  pk_columns: Role_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TagArgs = {
  _set?: InputMaybe<Tag_Set_Input>
  where: Tag_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tag_By_PkArgs = {
  _set?: InputMaybe<Tag_Set_Input>
  pk_columns: Tag_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Tag_FamilyArgs = {
  _set?: InputMaybe<Tag_Family_Set_Input>
  where: Tag_Family_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tag_Family_By_PkArgs = {
  _set?: InputMaybe<Tag_Family_Set_Input>
  pk_columns: Tag_Family_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Tag_Family_ManyArgs = {
  updates: Array<Tag_Family_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Tag_ManyArgs = {
  updates: Array<Tag_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "prop" */
export type Prop = {
  __typename?: 'prop'
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  image_path?: Maybe<Scalars['String']>
  /** An array relationship */
  list_props: Array<List_Prop>
  /** An aggregate relationship */
  list_props_aggregate: List_Prop_Aggregate
  name: Scalars['String']
  position?: Maybe<Scalars['String']>
  /** An array relationship */
  prop_tags: Array<Prop_Tag>
  /** An aggregate relationship */
  prop_tags_aggregate: Prop_Tag_Aggregate
  qty: Scalars['Int']
  size?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "prop" */
export type PropList_PropsArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

/** columns and relationships of "prop" */
export type PropList_Props_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

/** columns and relationships of "prop" */
export type PropProp_TagsArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

/** columns and relationships of "prop" */
export type PropProp_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

/** aggregated selection of "prop" */
export type Prop_Aggregate = {
  __typename?: 'prop_aggregate'
  aggregate?: Maybe<Prop_Aggregate_Fields>
  nodes: Array<Prop>
}

/** aggregate fields of "prop" */
export type Prop_Aggregate_Fields = {
  __typename?: 'prop_aggregate_fields'
  avg?: Maybe<Prop_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Prop_Max_Fields>
  min?: Maybe<Prop_Min_Fields>
  stddev?: Maybe<Prop_Stddev_Fields>
  stddev_pop?: Maybe<Prop_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Prop_Stddev_Samp_Fields>
  sum?: Maybe<Prop_Sum_Fields>
  var_pop?: Maybe<Prop_Var_Pop_Fields>
  var_samp?: Maybe<Prop_Var_Samp_Fields>
  variance?: Maybe<Prop_Variance_Fields>
}

/** aggregate fields of "prop" */
export type Prop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prop_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Prop_Avg_Fields = {
  __typename?: 'prop_avg_fields'
  qty?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "prop". All fields are combined with a logical 'AND'. */
export type Prop_Bool_Exp = {
  _and?: InputMaybe<Array<Prop_Bool_Exp>>
  _not?: InputMaybe<Prop_Bool_Exp>
  _or?: InputMaybe<Array<Prop_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  image_path?: InputMaybe<String_Comparison_Exp>
  list_props?: InputMaybe<List_Prop_Bool_Exp>
  list_props_aggregate?: InputMaybe<List_Prop_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  position?: InputMaybe<String_Comparison_Exp>
  prop_tags?: InputMaybe<Prop_Tag_Bool_Exp>
  prop_tags_aggregate?: InputMaybe<Prop_Tag_Aggregate_Bool_Exp>
  qty?: InputMaybe<Int_Comparison_Exp>
  size?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "prop" */
export enum Prop_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropPkey = 'prop_pkey',
}

/** input type for incrementing numeric columns in table "prop" */
export type Prop_Inc_Input = {
  qty?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "prop" */
export type Prop_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  image_path?: InputMaybe<Scalars['String']>
  list_props?: InputMaybe<List_Prop_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['String']>
  prop_tags?: InputMaybe<Prop_Tag_Arr_Rel_Insert_Input>
  qty?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Prop_Max_Fields = {
  __typename?: 'prop_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  image_path?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Prop_Min_Fields = {
  __typename?: 'prop_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  image_path?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "prop" */
export type Prop_Mutation_Response = {
  __typename?: 'prop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Prop>
}

/** input type for inserting object relation for remote table "prop" */
export type Prop_Obj_Rel_Insert_Input = {
  data: Prop_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Prop_On_Conflict>
}

/** on_conflict condition type for table "prop" */
export type Prop_On_Conflict = {
  constraint: Prop_Constraint
  update_columns?: Array<Prop_Update_Column>
  where?: InputMaybe<Prop_Bool_Exp>
}

/** Ordering options when selecting data from "prop". */
export type Prop_Order_By = {
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_path?: InputMaybe<Order_By>
  list_props_aggregate?: InputMaybe<List_Prop_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  prop_tags_aggregate?: InputMaybe<Prop_Tag_Aggregate_Order_By>
  qty?: InputMaybe<Order_By>
  size?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: prop */
export type Prop_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "prop" */
export enum Prop_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Qty = 'qty',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "prop" */
export type Prop_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  image_path?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Prop_Stddev_Fields = {
  __typename?: 'prop_stddev_fields'
  qty?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Prop_Stddev_Pop_Fields = {
  __typename?: 'prop_stddev_pop_fields'
  qty?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Prop_Stddev_Samp_Fields = {
  __typename?: 'prop_stddev_samp_fields'
  qty?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "prop" */
export type Prop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Prop_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  image_path?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Prop_Sum_Fields = {
  __typename?: 'prop_sum_fields'
  qty?: Maybe<Scalars['Int']>
}

/** columns and relationships of "prop_tag" */
export type Prop_Tag = {
  __typename?: 'prop_tag'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  prop: Prop
  prop_id: Scalars['uuid']
  /** An object relationship */
  tag: Tag
  tag_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "prop_tag" */
export type Prop_Tag_Aggregate = {
  __typename?: 'prop_tag_aggregate'
  aggregate?: Maybe<Prop_Tag_Aggregate_Fields>
  nodes: Array<Prop_Tag>
}

export type Prop_Tag_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prop_Tag_Aggregate_Bool_Exp_Count>
}

export type Prop_Tag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prop_Tag_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Prop_Tag_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "prop_tag" */
export type Prop_Tag_Aggregate_Fields = {
  __typename?: 'prop_tag_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Prop_Tag_Max_Fields>
  min?: Maybe<Prop_Tag_Min_Fields>
}

/** aggregate fields of "prop_tag" */
export type Prop_Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prop_Tag_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "prop_tag" */
export type Prop_Tag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Prop_Tag_Max_Order_By>
  min?: InputMaybe<Prop_Tag_Min_Order_By>
}

/** input type for inserting array relation for remote table "prop_tag" */
export type Prop_Tag_Arr_Rel_Insert_Input = {
  data: Array<Prop_Tag_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Prop_Tag_On_Conflict>
}

/** Boolean expression to filter rows from the table "prop_tag". All fields are combined with a logical 'AND'. */
export type Prop_Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Prop_Tag_Bool_Exp>>
  _not?: InputMaybe<Prop_Tag_Bool_Exp>
  _or?: InputMaybe<Array<Prop_Tag_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  prop?: InputMaybe<Prop_Bool_Exp>
  prop_id?: InputMaybe<Uuid_Comparison_Exp>
  tag?: InputMaybe<Tag_Bool_Exp>
  tag_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "prop_tag" */
export enum Prop_Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropTagPkey = 'prop_tag_pkey',
  /** unique or primary key constraint on columns "prop_id", "tag_id" */
  PropTagPropIdTagIdKey = 'prop_tag_prop_id_tag_id_key',
}

/** input type for inserting data into table "prop_tag" */
export type Prop_Tag_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  prop?: InputMaybe<Prop_Obj_Rel_Insert_Input>
  prop_id?: InputMaybe<Scalars['uuid']>
  tag?: InputMaybe<Tag_Obj_Rel_Insert_Input>
  tag_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Prop_Tag_Max_Fields = {
  __typename?: 'prop_tag_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  prop_id?: Maybe<Scalars['uuid']>
  tag_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "prop_tag" */
export type Prop_Tag_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  prop_id?: InputMaybe<Order_By>
  tag_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Prop_Tag_Min_Fields = {
  __typename?: 'prop_tag_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  prop_id?: Maybe<Scalars['uuid']>
  tag_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "prop_tag" */
export type Prop_Tag_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  prop_id?: InputMaybe<Order_By>
  tag_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "prop_tag" */
export type Prop_Tag_Mutation_Response = {
  __typename?: 'prop_tag_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Prop_Tag>
}

/** on_conflict condition type for table "prop_tag" */
export type Prop_Tag_On_Conflict = {
  constraint: Prop_Tag_Constraint
  update_columns?: Array<Prop_Tag_Update_Column>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

/** Ordering options when selecting data from "prop_tag". */
export type Prop_Tag_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  prop?: InputMaybe<Prop_Order_By>
  prop_id?: InputMaybe<Order_By>
  tag?: InputMaybe<Tag_Order_By>
  tag_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: prop_tag */
export type Prop_Tag_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "prop_tag" */
export enum Prop_Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropId = 'prop_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "prop_tag" */
export type Prop_Tag_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  prop_id?: InputMaybe<Scalars['uuid']>
  tag_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "prop_tag" */
export type Prop_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prop_Tag_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Prop_Tag_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  prop_id?: InputMaybe<Scalars['uuid']>
  tag_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "prop_tag" */
export enum Prop_Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropId = 'prop_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Prop_Tag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prop_Tag_Set_Input>
  /** filter the rows which have to be updated */
  where: Prop_Tag_Bool_Exp
}

/** update columns of table "prop" */
export enum Prop_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Qty = 'qty',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Prop_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prop_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prop_Set_Input>
  /** filter the rows which have to be updated */
  where: Prop_Bool_Exp
}

/** aggregate var_pop on columns */
export type Prop_Var_Pop_Fields = {
  __typename?: 'prop_var_pop_fields'
  qty?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Prop_Var_Samp_Fields = {
  __typename?: 'prop_var_samp_fields'
  qty?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Prop_Variance_Fields = {
  __typename?: 'prop_variance_fields'
  qty?: Maybe<Scalars['Float']>
}

export type Query_Root = {
  __typename?: 'query_root'
  api_crypt: Scalars['String']
  api_me: MeOutput
  api_ping: Scalars['String']
  /** fetch data from the table: "list" */
  list: Array<List>
  /** fetch aggregated fields from the table: "list" */
  list_aggregate: List_Aggregate
  /** fetch data from the table: "list" using primary key columns */
  list_by_pk?: Maybe<List>
  /** fetch data from the table: "list_prop" */
  list_prop: Array<List_Prop>
  /** fetch aggregated fields from the table: "list_prop" */
  list_prop_aggregate: List_Prop_Aggregate
  /** fetch data from the table: "list_prop" using primary key columns */
  list_prop_by_pk?: Maybe<List_Prop>
  /** fetch data from the table: "prop" */
  prop: Array<Prop>
  /** fetch aggregated fields from the table: "prop" */
  prop_aggregate: Prop_Aggregate
  /** fetch data from the table: "prop" using primary key columns */
  prop_by_pk?: Maybe<Prop>
  /** fetch data from the table: "prop_tag" */
  prop_tag: Array<Prop_Tag>
  /** fetch aggregated fields from the table: "prop_tag" */
  prop_tag_aggregate: Prop_Tag_Aggregate
  /** fetch data from the table: "prop_tag" using primary key columns */
  prop_tag_by_pk?: Maybe<Prop_Tag>
  /** fetch data from the table: "role" */
  role: Array<Role>
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>
  /** fetch data from the table: "tag" */
  tag: Array<Tag>
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>
  /** fetch data from the table: "tag_family" */
  tag_family: Array<Tag_Family>
  /** fetch aggregated fields from the table: "tag_family" */
  tag_family_aggregate: Tag_Family_Aggregate
  /** fetch data from the table: "tag_family" using primary key columns */
  tag_family_by_pk?: Maybe<Tag_Family>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
}

export type Query_RootApi_CryptArgs = {
  password: Scalars['String']
}

export type Query_RootListArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

export type Query_RootList_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

export type Query_RootList_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootList_PropArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

export type Query_RootList_Prop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

export type Query_RootList_Prop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPropArgs = {
  distinct_on?: InputMaybe<Array<Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Order_By>>
  where?: InputMaybe<Prop_Bool_Exp>
}

export type Query_RootProp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Order_By>>
  where?: InputMaybe<Prop_Bool_Exp>
}

export type Query_RootProp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootProp_TagArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

export type Query_RootProp_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

export type Query_RootProp_Tag_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Query_RootRole_By_PkArgs = {
  name: Scalars['String']
}

export type Query_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

export type Query_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

export type Query_RootTag_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootTag_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Tag_Family_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Family_Order_By>>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

export type Query_RootTag_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Family_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Family_Order_By>>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

export type Query_RootTag_Family_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role'
  name: Scalars['String']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
}

/** columns and relationships of "role" */
export type RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "role" */
export type RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate'
  aggregate?: Maybe<Role_Aggregate_Fields>
  nodes: Array<Role>
}

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Role_Max_Fields>
  min?: Maybe<Role_Min_Fields>
}

/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>
  _not?: InputMaybe<Role_Bool_Exp>
  _or?: InputMaybe<Array<Role_Bool_Exp>>
  name?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolePkey = 'role_pkey',
}

export enum Role_Enum {
  Admin = 'admin',
  User = 'user',
}

/** Boolean expression to compare columns of type "role_enum". All fields are combined with logical 'AND'. */
export type Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Enum>
  _in?: InputMaybe<Array<Role_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Role_Enum>
  _nin?: InputMaybe<Array<Role_Enum>>
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  name?: InputMaybe<Scalars['String']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields'
  name?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields'
  name?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Role>
}

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>
}

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint
  update_columns?: Array<Role_Update_Column>
  where?: InputMaybe<Role_Bool_Exp>
}

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  name?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  name: Scalars['String']
}

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  name?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>
}

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Name = 'name',
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "list" */
  list: Array<List>
  /** fetch aggregated fields from the table: "list" */
  list_aggregate: List_Aggregate
  /** fetch data from the table: "list" using primary key columns */
  list_by_pk?: Maybe<List>
  /** fetch data from the table: "list_prop" */
  list_prop: Array<List_Prop>
  /** fetch aggregated fields from the table: "list_prop" */
  list_prop_aggregate: List_Prop_Aggregate
  /** fetch data from the table: "list_prop" using primary key columns */
  list_prop_by_pk?: Maybe<List_Prop>
  /** fetch data from the table in a streaming manner: "list_prop" */
  list_prop_stream: Array<List_Prop>
  /** fetch data from the table in a streaming manner: "list" */
  list_stream: Array<List>
  /** fetch data from the table: "prop" */
  prop: Array<Prop>
  /** fetch aggregated fields from the table: "prop" */
  prop_aggregate: Prop_Aggregate
  /** fetch data from the table: "prop" using primary key columns */
  prop_by_pk?: Maybe<Prop>
  /** fetch data from the table in a streaming manner: "prop" */
  prop_stream: Array<Prop>
  /** fetch data from the table: "prop_tag" */
  prop_tag: Array<Prop_Tag>
  /** fetch aggregated fields from the table: "prop_tag" */
  prop_tag_aggregate: Prop_Tag_Aggregate
  /** fetch data from the table: "prop_tag" using primary key columns */
  prop_tag_by_pk?: Maybe<Prop_Tag>
  /** fetch data from the table in a streaming manner: "prop_tag" */
  prop_tag_stream: Array<Prop_Tag>
  /** fetch data from the table: "role" */
  role: Array<Role>
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>
  /** fetch data from the table: "tag" */
  tag: Array<Tag>
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>
  /** fetch data from the table: "tag_family" */
  tag_family: Array<Tag_Family>
  /** fetch aggregated fields from the table: "tag_family" */
  tag_family_aggregate: Tag_Family_Aggregate
  /** fetch data from the table: "tag_family" using primary key columns */
  tag_family_by_pk?: Maybe<Tag_Family>
  /** fetch data from the table in a streaming manner: "tag_family" */
  tag_family_stream: Array<Tag_Family>
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<Tag>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
}

export type Subscription_RootListArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

export type Subscription_RootList_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

export type Subscription_RootList_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootList_PropArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

export type Subscription_RootList_Prop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Prop_Order_By>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

export type Subscription_RootList_Prop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootList_Prop_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<List_Prop_Stream_Cursor_Input>>
  where?: InputMaybe<List_Prop_Bool_Exp>
}

export type Subscription_RootList_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<List_Stream_Cursor_Input>>
  where?: InputMaybe<List_Bool_Exp>
}

export type Subscription_RootPropArgs = {
  distinct_on?: InputMaybe<Array<Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Order_By>>
  where?: InputMaybe<Prop_Bool_Exp>
}

export type Subscription_RootProp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Order_By>>
  where?: InputMaybe<Prop_Bool_Exp>
}

export type Subscription_RootProp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootProp_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Prop_Stream_Cursor_Input>>
  where?: InputMaybe<Prop_Bool_Exp>
}

export type Subscription_RootProp_TagArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

export type Subscription_RootProp_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

export type Subscription_RootProp_Tag_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootProp_Tag_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Prop_Tag_Stream_Cursor_Input>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Role_Order_By>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootRole_By_PkArgs = {
  name: Scalars['String']
}

export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>
  where?: InputMaybe<Role_Bool_Exp>
}

export type Subscription_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

export type Subscription_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootTag_FamilyArgs = {
  distinct_on?: InputMaybe<Array<Tag_Family_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Family_Order_By>>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

export type Subscription_RootTag_Family_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Family_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Family_Order_By>>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

export type Subscription_RootTag_Family_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootTag_Family_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Tag_Family_Stream_Cursor_Input>>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

export type Subscription_RootTag_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Tag_Stream_Cursor_Input>>
  where?: InputMaybe<Tag_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  name: Scalars['String']
  /** An array relationship */
  prop_tags: Array<Prop_Tag>
  /** An aggregate relationship */
  prop_tags_aggregate: Prop_Tag_Aggregate
  /** An object relationship */
  tag_family: Tag_Family
  tag_family_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "tag" */
export type TagProp_TagsArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

/** columns and relationships of "tag" */
export type TagProp_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prop_Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Prop_Tag_Order_By>>
  where?: InputMaybe<Prop_Tag_Bool_Exp>
}

/** aggregated selection of "tag" */
export type Tag_Aggregate = {
  __typename?: 'tag_aggregate'
  aggregate?: Maybe<Tag_Aggregate_Fields>
  nodes: Array<Tag>
}

export type Tag_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tag_Aggregate_Bool_Exp_Count>
}

export type Tag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tag_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Tag_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "tag" */
export type Tag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Tag_Max_Fields>
  min?: Maybe<Tag_Min_Fields>
}

/** aggregate fields of "tag" */
export type Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "tag" */
export type Tag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Tag_Max_Order_By>
  min?: InputMaybe<Tag_Min_Order_By>
}

/** input type for inserting array relation for remote table "tag" */
export type Tag_Arr_Rel_Insert_Input = {
  data: Array<Tag_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_On_Conflict>
}

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Bool_Exp>>
  _not?: InputMaybe<Tag_Bool_Exp>
  _or?: InputMaybe<Array<Tag_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  prop_tags?: InputMaybe<Prop_Tag_Bool_Exp>
  prop_tags_aggregate?: InputMaybe<Prop_Tag_Aggregate_Bool_Exp>
  tag_family?: InputMaybe<Tag_Family_Bool_Exp>
  tag_family_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "tag" */
export enum Tag_Constraint {
  /** unique or primary key constraint on columns "tag_family_id", "name" */
  TagNameTagFamilyIdKey = 'tag_name_tag_family_id_key',
  /** unique or primary key constraint on columns "id" */
  TagPkey = 'tag_pkey',
}

/** columns and relationships of "tag_family" */
export type Tag_Family = {
  __typename?: 'tag_family'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  name: Scalars['String']
  /** An array relationship */
  tags: Array<Tag>
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "tag_family" */
export type Tag_FamilyTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

/** columns and relationships of "tag_family" */
export type Tag_FamilyTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Tag_Order_By>>
  where?: InputMaybe<Tag_Bool_Exp>
}

/** aggregated selection of "tag_family" */
export type Tag_Family_Aggregate = {
  __typename?: 'tag_family_aggregate'
  aggregate?: Maybe<Tag_Family_Aggregate_Fields>
  nodes: Array<Tag_Family>
}

/** aggregate fields of "tag_family" */
export type Tag_Family_Aggregate_Fields = {
  __typename?: 'tag_family_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Tag_Family_Max_Fields>
  min?: Maybe<Tag_Family_Min_Fields>
}

/** aggregate fields of "tag_family" */
export type Tag_Family_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Family_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "tag_family". All fields are combined with a logical 'AND'. */
export type Tag_Family_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Family_Bool_Exp>>
  _not?: InputMaybe<Tag_Family_Bool_Exp>
  _or?: InputMaybe<Array<Tag_Family_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  tags?: InputMaybe<Tag_Bool_Exp>
  tags_aggregate?: InputMaybe<Tag_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "tag_family" */
export enum Tag_Family_Constraint {
  /** unique or primary key constraint on columns "name" */
  TagFamilyNameKey = 'tag_family_name_key',
  /** unique or primary key constraint on columns "id" */
  TagFamilyPkey = 'tag_family_pkey',
}

/** input type for inserting data into table "tag_family" */
export type Tag_Family_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Tag_Family_Max_Fields = {
  __typename?: 'tag_family_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Tag_Family_Min_Fields = {
  __typename?: 'tag_family_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "tag_family" */
export type Tag_Family_Mutation_Response = {
  __typename?: 'tag_family_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Family>
}

/** input type for inserting object relation for remote table "tag_family" */
export type Tag_Family_Obj_Rel_Insert_Input = {
  data: Tag_Family_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_Family_On_Conflict>
}

/** on_conflict condition type for table "tag_family" */
export type Tag_Family_On_Conflict = {
  constraint: Tag_Family_Constraint
  update_columns?: Array<Tag_Family_Update_Column>
  where?: InputMaybe<Tag_Family_Bool_Exp>
}

/** Ordering options when selecting data from "tag_family". */
export type Tag_Family_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: tag_family */
export type Tag_Family_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "tag_family" */
export enum Tag_Family_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tag_family" */
export type Tag_Family_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "tag_family" */
export type Tag_Family_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Family_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Tag_Family_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "tag_family" */
export enum Tag_Family_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tag_Family_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Family_Set_Input>
  /** filter the rows which have to be updated */
  where: Tag_Family_Bool_Exp
}

/** input type for inserting data into table "tag" */
export type Tag_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  prop_tags?: InputMaybe<Prop_Tag_Arr_Rel_Insert_Input>
  tag_family?: InputMaybe<Tag_Family_Obj_Rel_Insert_Input>
  tag_family_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Tag_Max_Fields = {
  __typename?: 'tag_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  tag_family_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "tag" */
export type Tag_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tag_family_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Tag_Min_Fields = {
  __typename?: 'tag_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  tag_family_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "tag" */
export type Tag_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  tag_family_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "tag" */
export type Tag_Mutation_Response = {
  __typename?: 'tag_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Tag>
}

/** input type for inserting object relation for remote table "tag" */
export type Tag_Obj_Rel_Insert_Input = {
  data: Tag_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_On_Conflict>
}

/** on_conflict condition type for table "tag" */
export type Tag_On_Conflict = {
  constraint: Tag_Constraint
  update_columns?: Array<Tag_Update_Column>
  where?: InputMaybe<Tag_Bool_Exp>
}

/** Ordering options when selecting data from "tag". */
export type Tag_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  prop_tags_aggregate?: InputMaybe<Prop_Tag_Aggregate_Order_By>
  tag_family?: InputMaybe<Tag_Family_Order_By>
  tag_family_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: tag */
export type Tag_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TagFamilyId = 'tag_family_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tag" */
export type Tag_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tag_family_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "tag" */
export type Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Tag_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  tag_family_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "tag" */
export enum Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TagFamilyId = 'tag_family_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Set_Input>
  /** filter the rows which have to be updated */
  where: Tag_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  created_at: Scalars['timestamptz']
  email: Scalars['String']
  firstname: Scalars['String']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  lastname: Scalars['String']
  /** An array relationship */
  lists: Array<List>
  /** An aggregate relationship */
  lists_aggregate: List_Aggregate
  password: Scalars['String']
  role: Role_Enum
  /** An object relationship */
  roleByRole: Role
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "user" */
export type UserListsArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<List_Order_By>>
  where?: InputMaybe<List_Bool_Exp>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Max_Order_By>
  min?: InputMaybe<User_Min_Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>
  _not?: InputMaybe<User_Bool_Exp>
  _or?: InputMaybe<Array<User_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  firstname?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_active?: InputMaybe<Boolean_Comparison_Exp>
  lastname?: InputMaybe<String_Comparison_Exp>
  lists?: InputMaybe<List_Bool_Exp>
  lists_aggregate?: InputMaybe<List_Aggregate_Bool_Exp>
  password?: InputMaybe<String_Comparison_Exp>
  role?: InputMaybe<Role_Enum_Comparison_Exp>
  roleByRole?: InputMaybe<Role_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firstname?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_active?: InputMaybe<Scalars['Boolean']>
  lastname?: InputMaybe<Scalars['String']>
  lists?: InputMaybe<List_Arr_Rel_Insert_Input>
  password?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Role_Enum>
  roleByRole?: InputMaybe<Role_Obj_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  firstname?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  lastname?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  password?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  firstname?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  lastname?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  password?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: InputMaybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  firstname?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_active?: InputMaybe<Order_By>
  lastname?: InputMaybe<Order_By>
  lists_aggregate?: InputMaybe<List_Aggregate_Order_By>
  password?: InputMaybe<Order_By>
  role?: InputMaybe<Order_By>
  roleByRole?: InputMaybe<Role_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firstname?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_active?: InputMaybe<Scalars['Boolean']>
  lastname?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  firstname?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_active?: InputMaybe<Scalars['Boolean']>
  lastname?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type ApiInsertUserMutationVariables = Exact<{
  email: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  password: Scalars['String']
  role: Scalars['String']
  is_active: Scalars['Boolean']
}>

export type ApiInsertUserMutation = {
  __typename?: 'mutation_root'
  api_insert_user: {
    __typename?: 'InsertUserOutput'
    insertUserOutputId: string
    insertUserOutputEmail: string
    insertUserOutputFirstname: string
    insertUserOutputLastname: string
    insertUserOutputRole: string
    insertUserOutputIsActive: boolean
  }
}

export type ApiUpdateMeMutationVariables = Exact<{
  email: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  password: Scalars['String']
}>

export type ApiUpdateMeMutation = {
  __typename?: 'mutation_root'
  api_update_me: {
    __typename?: 'MeOutput'
    meOutputId: string
    meOutputEmail: string
    meOutputFirstname: string
    meOutputLastname: string
    meOutputRole: string
  }
}

export type ApiUpdateUserMutationVariables = Exact<{
  id: Scalars['String']
  email: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  password: Scalars['String']
  role: Scalars['String']
  is_active: Scalars['Boolean']
}>

export type ApiUpdateUserMutation = {
  __typename?: 'mutation_root'
  api_update_user: {
    __typename?: 'UpdateUserOutput'
    updateUserOutputId: string
    updateUserOutputEmail: string
    updateUserOutputFirstname: string
    updateUserOutputLastname: string
    updateUserOutputRole: string
    updateUserOutputIsActive: boolean
  }
}

export type ApiUploadFileMutationVariables = Exact<{
  prop_id: Scalars['String']
  name: Scalars['String']
  type: Scalars['String']
  base64str: Scalars['String']
}>

export type ApiUploadFileMutation = {
  __typename?: 'mutation_root'
  api_upload_file: { __typename?: 'FileOutput'; filepath: string }
}

export type DeleteListByPkMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteListByPkMutation = {
  __typename?: 'mutation_root'
  delete_list_by_pk?:
    | { __typename?: 'list'; id: any; name: string }
    | null
    | undefined
}

export type DeletePropByPkMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeletePropByPkMutation = {
  __typename?: 'mutation_root'
  delete_prop_by_pk?:
    | { __typename?: 'prop'; id: any; name: string }
    | null
    | undefined
}

export type DeleteTagByPkMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteTagByPkMutation = {
  __typename?: 'mutation_root'
  delete_tag_by_pk?:
    | { __typename?: 'tag'; id: any; name: string }
    | null
    | undefined
}

export type DeleteTagFamilyByPkMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteTagFamilyByPkMutation = {
  __typename?: 'mutation_root'
  delete_tag_family_by_pk?:
    | { __typename?: 'tag_family'; id: any; name: string }
    | null
    | undefined
}

export type DeleteUserByPkMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type DeleteUserByPkMutation = {
  __typename?: 'mutation_root'
  delete_user_by_pk?:
    | {
        __typename?: 'user'
        id: any
        email: string
        firstname: string
        lastname: string
        role: Role_Enum
        is_active: boolean
      }
    | null
    | undefined
}

export type InsertListMutationVariables = Exact<{
  user_id: Scalars['uuid']
  name: Scalars['String']
}>

export type InsertListMutation = {
  __typename?: 'mutation_root'
  insert_list_one?:
    | { __typename?: 'list'; id: any; name: string }
    | null
    | undefined
}

export type InsertPropMutationVariables = Exact<{
  name: Scalars['String']
  description: Scalars['String']
  position: Scalars['String']
  size: Scalars['String']
  qty?: InputMaybe<Scalars['Int']>
}>

export type InsertPropMutation = {
  __typename?: 'mutation_root'
  insert_prop_one?:
    | {
        __typename?: 'prop'
        id: any
        name: string
        description?: string | null | undefined
        position?: string | null | undefined
        size?: string | null | undefined
        qty: number
      }
    | null
    | undefined
}

export type InsertTagMutationVariables = Exact<{
  name: Scalars['String']
  tag_family_id: Scalars['uuid']
}>

export type InsertTagMutation = {
  __typename?: 'mutation_root'
  insert_tag_one?:
    | { __typename?: 'tag'; id: any; name: string }
    | null
    | undefined
}

export type InsertTagFamilyMutationVariables = Exact<{
  name: Scalars['String']
}>

export type InsertTagFamilyMutation = {
  __typename?: 'mutation_root'
  insert_tag_family_one?:
    | { __typename?: 'tag_family'; id: any; name: string }
    | null
    | undefined
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type LoginMutation = {
  __typename?: 'mutation_root'
  api_login: { __typename?: 'LoginOutput'; loginOutputAccessToken: string }
}

export type UpdateAllListPropMutationVariables = Exact<{
  delete_where: List_Prop_Bool_Exp
  insert_objects: Array<List_Prop_Insert_Input> | List_Prop_Insert_Input
}>

export type UpdateAllListPropMutation = {
  __typename?: 'mutation_root'
  delete_list_prop?:
    | { __typename?: 'list_prop_mutation_response'; affected_rows: number }
    | null
    | undefined
  insert_list_prop?:
    | { __typename?: 'list_prop_mutation_response'; affected_rows: number }
    | null
    | undefined
}

export type UpdateAllPropTagMutationVariables = Exact<{
  delete_where: Prop_Tag_Bool_Exp
  insert_objects: Array<Prop_Tag_Insert_Input> | Prop_Tag_Insert_Input
}>

export type UpdateAllPropTagMutation = {
  __typename?: 'mutation_root'
  delete_prop_tag?:
    | { __typename?: 'prop_tag_mutation_response'; affected_rows: number }
    | null
    | undefined
  insert_prop_tag?:
    | { __typename?: 'prop_tag_mutation_response'; affected_rows: number }
    | null
    | undefined
}

export type UpdateListByPkMutationVariables = Exact<{
  id: Scalars['uuid']
  name: Scalars['String']
}>

export type UpdateListByPkMutation = {
  __typename?: 'mutation_root'
  update_list_by_pk?:
    | { __typename?: 'list'; id: any; name: string }
    | null
    | undefined
}

export type UpdatePropByPkMutationVariables = Exact<{
  id: Scalars['uuid']
  name: Scalars['String']
  description: Scalars['String']
  position: Scalars['String']
  size: Scalars['String']
  qty?: InputMaybe<Scalars['Int']>
}>

export type UpdatePropByPkMutation = {
  __typename?: 'mutation_root'
  update_prop_by_pk?:
    | {
        __typename?: 'prop'
        id: any
        name: string
        description?: string | null | undefined
        position?: string | null | undefined
        size?: string | null | undefined
        qty: number
      }
    | null
    | undefined
}

export type UpdateTagByPkMutationVariables = Exact<{
  id: Scalars['uuid']
  name: Scalars['String']
  tag_family_id: Scalars['uuid']
}>

export type UpdateTagByPkMutation = {
  __typename?: 'mutation_root'
  update_tag_by_pk?:
    | { __typename?: 'tag'; id: any; name: string }
    | null
    | undefined
}

export type UpdateTagFamilyByPkMutationVariables = Exact<{
  id: Scalars['uuid']
  name: Scalars['String']
}>

export type UpdateTagFamilyByPkMutation = {
  __typename?: 'mutation_root'
  update_tag_family_by_pk?:
    | { __typename?: 'tag_family'; id: any; name: string }
    | null
    | undefined
}

export type ApiMeQueryVariables = Exact<{ [key: string]: never }>

export type ApiMeQuery = {
  __typename?: 'query_root'
  api_me: {
    __typename?: 'MeOutput'
    meOutputId: string
    meOutputEmail: string
    meOutputFirstname: string
    meOutputLastname: string
    meOutputRole: string
  }
}

export type ApiPingQueryVariables = Exact<{ [key: string]: never }>

export type ApiPingQuery = { __typename?: 'query_root'; api_ping: string }

export type ListsSubscriptionVariables = Exact<{
  user_id: Scalars['uuid']
}>

export type ListsSubscription = {
  __typename?: 'subscription_root'
  list: Array<{
    __typename?: 'list'
    id: any
    name: string
    created_at: any
    updated_at: any
  }>
}

export type ListsPropsSubscriptionVariables = Exact<{
  user_id: Scalars['uuid']
}>

export type ListsPropsSubscription = {
  __typename?: 'subscription_root'
  list_prop: Array<{
    __typename?: 'list_prop'
    id: any
    list_id: any
    prop_id: any
    created_at: any
    updated_at: any
  }>
}

export type PropsSubscriptionVariables = Exact<{ [key: string]: never }>

export type PropsSubscription = {
  __typename?: 'subscription_root'
  prop: Array<{
    __typename?: 'prop'
    id: any
    position?: string | null | undefined
    image_path?: string | null | undefined
    name: string
    description?: string | null | undefined
    size?: string | null | undefined
    qty: number
    created_at: any
    updated_at: any
    prop_tags: Array<{
      __typename?: 'prop_tag'
      tag: {
        __typename?: 'tag'
        id: any
        name: string
        tag_family: { __typename?: 'tag_family'; id: any; name: string }
      }
    }>
  }>
}

export type TagFamiliesSubscriptionVariables = Exact<{ [key: string]: never }>

export type TagFamiliesSubscription = {
  __typename?: 'subscription_root'
  tag_family: Array<{
    __typename?: 'tag_family'
    id: any
    name: string
    created_at: any
    updated_at: any
  }>
}

export type TagsSubscriptionVariables = Exact<{ [key: string]: never }>

export type TagsSubscription = {
  __typename?: 'subscription_root'
  tag: Array<{
    __typename?: 'tag'
    id: any
    name: string
    created_at: any
    updated_at: any
    tag_family: { __typename?: 'tag_family'; id: any; name: string }
  }>
}

export type UsersSubscriptionVariables = Exact<{ [key: string]: never }>

export type UsersSubscription = {
  __typename?: 'subscription_root'
  user: Array<{
    __typename?: 'user'
    id: any
    email: string
    firstname: string
    lastname: string
    role: Role_Enum
    is_active: boolean
    created_at: any
    updated_at: any
  }>
}

export const ApiInsertUserDocument = gql`
  mutation ApiInsertUser(
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $role: String!
    $is_active: Boolean!
  ) {
    api_insert_user(
      insertUserInputEmail: $email
      insertUserInputFirstname: $firstname
      insertUserInputLastname: $lastname
      insertUserInputPassword: $password
      insertUserInputRole: $role
      insertUserInputIsActive: $is_active
    ) {
      insertUserOutputId
      insertUserOutputEmail
      insertUserOutputFirstname
      insertUserOutputLastname
      insertUserOutputRole
      insertUserOutputIsActive
    }
  }
`
export const ApiUpdateMeDocument = gql`
  mutation ApiUpdateMe(
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
  ) {
    api_update_me(
      updateMeInputEmail: $email
      updateMeInputFirstname: $firstname
      updateMeInputLastname: $lastname
      updateMeInputPassword: $password
    ) {
      meOutputId
      meOutputEmail
      meOutputFirstname
      meOutputLastname
      meOutputRole
    }
  }
`
export const ApiUpdateUserDocument = gql`
  mutation ApiUpdateUser(
    $id: String!
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $role: String!
    $is_active: Boolean!
  ) {
    api_update_user(
      updateUserInputId: $id
      updateUserInputEmail: $email
      updateUserInputFirstname: $firstname
      updateUserInputLastname: $lastname
      updateUserInputPassword: $password
      updateUserInputRole: $role
      updateUserInputIsActive: $is_active
    ) {
      updateUserOutputId
      updateUserOutputEmail
      updateUserOutputFirstname
      updateUserOutputLastname
      updateUserOutputRole
      updateUserOutputIsActive
    }
  }
`
export const ApiUploadFileDocument = gql`
  mutation ApiUploadFile(
    $prop_id: String!
    $name: String!
    $type: String!
    $base64str: String!
  ) {
    api_upload_file(
      prop_id: $prop_id
      name: $name
      type: $type
      base64str: $base64str
    ) {
      filepath
    }
  }
`
export const DeleteListByPkDocument = gql`
  mutation DeleteListByPk($id: uuid!) {
    delete_list_by_pk(id: $id) {
      id
      name
    }
  }
`
export const DeletePropByPkDocument = gql`
  mutation DeletePropByPk($id: uuid!) {
    delete_prop_by_pk(id: $id) {
      id
      name
    }
  }
`
export const DeleteTagByPkDocument = gql`
  mutation DeleteTagByPk($id: uuid!) {
    delete_tag_by_pk(id: $id) {
      id
      name
    }
  }
`
export const DeleteTagFamilyByPkDocument = gql`
  mutation DeleteTagFamilyByPk($id: uuid!) {
    delete_tag_family_by_pk(id: $id) {
      id
      name
    }
  }
`
export const DeleteUserByPkDocument = gql`
  mutation DeleteUserByPk($id: uuid!) {
    delete_user_by_pk(id: $id) {
      id
      email
      firstname
      lastname
      role
      is_active
    }
  }
`
export const InsertListDocument = gql`
  mutation InsertList($user_id: uuid!, $name: String!) {
    insert_list_one(object: { user_id: $user_id, name: $name }) {
      id
      name
    }
  }
`
export const InsertPropDocument = gql`
  mutation InsertProp(
    $name: String!
    $description: String!
    $position: String!
    $size: String!
    $qty: Int
  ) {
    insert_prop_one(
      object: {
        name: $name
        description: $description
        position: $position
        size: $size
        qty: $qty
      }
    ) {
      id
      name
      description
      position
      size
      qty
    }
  }
`
export const InsertTagDocument = gql`
  mutation InsertTag($name: String!, $tag_family_id: uuid!) {
    insert_tag_one(object: { name: $name, tag_family_id: $tag_family_id }) {
      id
      name
    }
  }
`
export const InsertTagFamilyDocument = gql`
  mutation InsertTagFamily($name: String!) {
    insert_tag_family_one(object: { name: $name }) {
      id
      name
    }
  }
`
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    api_login(loginInputEmail: $email, loginInputPassword: $password) {
      loginOutputAccessToken
    }
  }
`
export const UpdateAllListPropDocument = gql`
  mutation UpdateAllListProp(
    $delete_where: list_prop_bool_exp!
    $insert_objects: [list_prop_insert_input!]!
  ) {
    delete_list_prop(where: $delete_where) {
      affected_rows
    }
    insert_list_prop(
      objects: $insert_objects
      on_conflict: {
        constraint: list_prop_list_id_prop_id_key
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`
export const UpdateAllPropTagDocument = gql`
  mutation UpdateAllPropTag(
    $delete_where: prop_tag_bool_exp!
    $insert_objects: [prop_tag_insert_input!]!
  ) {
    delete_prop_tag(where: $delete_where) {
      affected_rows
    }
    insert_prop_tag(
      objects: $insert_objects
      on_conflict: {
        constraint: prop_tag_prop_id_tag_id_key
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`
export const UpdateListByPkDocument = gql`
  mutation UpdateListByPk($id: uuid!, $name: String!) {
    update_list_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`
export const UpdatePropByPkDocument = gql`
  mutation UpdatePropByPk(
    $id: uuid!
    $name: String!
    $description: String!
    $position: String!
    $size: String!
    $qty: Int
  ) {
    update_prop_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        position: $position
        size: $size
        qty: $qty
      }
    ) {
      id
      name
      description
      position
      size
      qty
    }
  }
`
export const UpdateTagByPkDocument = gql`
  mutation UpdateTagByPk($id: uuid!, $name: String!, $tag_family_id: uuid!) {
    update_tag_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, tag_family_id: $tag_family_id }
    ) {
      id
      name
    }
  }
`
export const UpdateTagFamilyByPkDocument = gql`
  mutation UpdateTagFamilyByPk($id: uuid!, $name: String!) {
    update_tag_family_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`
export const ApiMeDocument = gql`
  query ApiMe {
    api_me {
      meOutputId
      meOutputEmail
      meOutputFirstname
      meOutputLastname
      meOutputRole
    }
  }
`
export const ApiPingDocument = gql`
  query ApiPing {
    api_ping
  }
`
export const ListsDocument = gql`
  subscription Lists($user_id: uuid!) {
    list(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: desc }
    ) {
      id
      name
      created_at
      updated_at
    }
  }
`
export const ListsPropsDocument = gql`
  subscription ListsProps($user_id: uuid!) {
    list_prop(where: { list: { user_id: { _eq: $user_id } } }) {
      id
      list_id
      prop_id
      created_at
      updated_at
    }
  }
`
export const PropsDocument = gql`
  subscription Props {
    prop {
      id
      position
      image_path
      name
      description
      size
      qty
      prop_tags {
        tag {
          id
          name
          tag_family {
            id
            name
          }
        }
      }
      created_at
      updated_at
    }
  }
`
export const TagFamiliesDocument = gql`
  subscription TagFamilies {
    tag_family {
      id
      name
      created_at
      updated_at
    }
  }
`
export const TagsDocument = gql`
  subscription Tags {
    tag {
      id
      name
      created_at
      updated_at
      tag_family {
        id
        name
      }
    }
  }
`
export const UsersDocument = gql`
  subscription Users {
    user {
      id
      email
      firstname
      lastname
      role
      is_active
      created_at
      updated_at
    }
  }
`
