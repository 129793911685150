



















































































































import { Vue } from 'nuxt-property-decorator'
import Component from 'nuxt-class-component'
import { State } from 'vuex-class'
import {
  ApiMeQuery,
  ApiUpdateMeDocument,
  ApiUpdateMeMutationVariables,
} from '~/graphql/types'

@Component
export default class MenuMyAccount extends Vue {
  @State('me') me: ApiMeQuery['api_me'] | undefined

  editedMe: ApiUpdateMeMutationVariables = {
    email: '',
    firstname: '',
    lastname: '',
    password: '',
  }

  dialogMe: boolean = false
  isSavingMe: boolean = false

  $apollo: any
  formRules: any

  async mounted() {
    await this.$store.dispatch('fetchMe', { forceFetch: true })
  }

  editMe() {
    this.editedMe = {
      email: this.me?.meOutputEmail || '',
      firstname: this.me?.meOutputFirstname || '',
      lastname: this.me?.meOutputLastname || '',
      password: '',
    }
    this.dialogMe = true
  }

  closeDialogMe() {
    this.dialogMe = false
    setTimeout(() => {
      this.editedMe = {
        email: this.me?.meOutputEmail || '',
        firstname: this.me?.meOutputFirstname || '',
        lastname: this.me?.meOutputLastname || '',
        password: '',
      }
    }, 300)
  }

  get canSaveMe() {
    if (this.isSavingMe) {
      return false
    }
    const requiredFields = ['firstname', 'lastname', 'email']
    let can = true
    requiredFields.forEach((field) => {
      if (!can) {
        return 0
      } else if (
        this.editedMe[field] === '' ||
        this.editedMe[field].length === 0
      ) {
        can = false
      }
    })
    if (can) {
      can = this.formRules.email(this.editedMe.email) === true
    }
    return can
  }

  async saveMe() {
    if (this.isSavingMe) {
      return
    }
    this.isSavingMe = true
    try {
      await this.$apollo.mutate({
        mutation: ApiUpdateMeDocument,
        variables: {
          email: this.editedMe.email,
          firstname: this.editedMe.firstname,
          lastname: this.editedMe.lastname,
          password: Object.prototype.hasOwnProperty.call(
            this.editedMe,
            'password'
          )
            ? this.editedMe.password
            : '',
        } as ApiUpdateMeMutationVariables,
      })
      await this.$store.dispatch('fetchMe', { forceFetch: true })
      await this.$store.commit('setLastSuccessMessage', '')
      await this.$store.commit(
        'setLastSuccessMessage',
        'Profile was successfully updated'
      )
    } catch (e) {}

    this.isSavingMe = false
    this.closeDialogMe()
  }
}
