// eslint-disable-next-line import/named
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/types'
import {
  ApiMeDocument,
  ApiMeQuery,
} from '~/graphql/types'

export const state = (): RootState => ({
  me: undefined,
  lastSuccessMessage: '',
  lastErrorMessage: '',
})

export const actions: ActionTree<any, RootState> = {
  async loginLogout({ commit }) {
    await commit('setMe', undefined)
  },
  async fetchMe({ commit }, { forceFetch }) {
    if (this.state.me === undefined || forceFetch === true) {
      const me: ApiMeQuery = (
        await this.app.apolloProvider.defaultClient.query({
          query: ApiMeDocument,
        })
      ).data
      commit('setMe', me.api_me)
    }
  },
}

export const mutations: MutationTree<RootState> = {
  setMe(state: RootState, value: any): void {
    state.me = value
  },
  setLastSuccessMessage(state: RootState, value: string): void {
    state.lastSuccessMessage = value
  },
  setLastErrorMessage(state: RootState, value: string): void {
    state.lastErrorMessage = value
  },
}
