import Vue from 'vue'
import JWTDecode from 'jwt-decode'
import * as GraphqlTypes from '~/graphql/types'

Vue.mixin({
  data() {
    return {
      formRules: {
        required: (value) => !!value || 'Required.',
        integer: (value) => /^[1-9][0-9]*$/.test(value) || 'Invalid Integer.',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    rolesCurrentUser() {
      const token = this.$apolloHelpers.getToken()
      if (token) {
        const tokenDecoded: any = JWTDecode(token)
        return tokenDecoded['https://hasura.io/jwt/claims'][
          'x-hasura-allowed-roles'
        ]
      }
      return false
    },
    hasAdminRole() {
      return (
        this.rolesCurrentUser &&
        this.rolesCurrentUser.includes(GraphqlTypes.Role_Enum.Admin)
      )
    },
  },
})
