



























import { Vue, Component, State, Watch } from 'nuxt-property-decorator'

@Component
export default class SnackBarMessagesSuccess extends Vue {
  @State('lastSuccessMessage') lastSuccessMessage

  showSnackbarSuccess = false

  mounted() {
    this.showSnackbarSuccess = this.lastSuccessMessage !== ''
  }

  @Watch('lastSuccessMessage')
  onLastSuccessMessageChanged(value: string) {
    this.showSnackbarSuccess = value !== ''
  }

  @Watch('showSnackbar')
  onShowSnackbarChanged(value: boolean) {
    if (!value) {
      this.$store.commit('setLastSuccessMessage', '')
    }
  }
}
